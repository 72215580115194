import { Box, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { useFilterChipsSectionStyles } from './hooks'

import {
  areas,
  experienceLevels,
  specializations,
  specialOffers
} from '../../../../../utils/practitionerListing'

import { Button } from '../../../../../Button'
import { Chip } from '../../../../../Chip'

import {
  clearFilterOptions,
  fetchPractitionerListings,
  selectAreaFilterOptions,
  selectExperienceFilterOptions,
  selectMaxStartPriceFilterOption,
  selectSpecialisationFilterOptions,
  selectSpecialOfferFilterOptions,
  setAreaFilterOptions,
  setExperienceFilterOptions,
  setMaxStartPriceFilterOption,
  setSpecialisationFilterOptions,
  setSpecialOfferFilterOptions
} from '../../../../../../../containers/mui/SearchPage/duck'

const getDisplayValue = (array, searchValue) => {
  const targetEntry = array.find((entry) => entry.value === searchValue)
  if (targetEntry) {
    return targetEntry.displayValue
  }

  return undefined
}

export const FilterChipsSection = () => {
  const dispatch = useDispatch()
  const specialOfferFilters = useSelector(selectSpecialOfferFilterOptions)
  const areaFilters = useSelector(selectAreaFilterOptions)
  const experienceFilters = useSelector(selectExperienceFilterOptions)
  const specialisationFilters = useSelector(selectSpecialisationFilterOptions)
  const maxStartPriceFilter = useSelector(selectMaxStartPriceFilterOption)
  const { classes } = useFilterChipsSectionStyles()

  const shouldRenderFilterChips =
    specialOfferFilters.length > 0 ||
    areaFilters.length > 0 ||
    experienceFilters.length > 0 ||
    specialisationFilters.length > 0 ||
    (maxStartPriceFilter && maxStartPriceFilter > 0)

  const clearAllClickHandler = () => {
    dispatch(clearFilterOptions())
    dispatch(fetchPractitionerListings())
  }

  const renderFilterChips = () => {
    let renderedChips = []

    renderedChips.push(
      specialOfferFilters.map((filter) => {
        const clickHandler = (value) => {
          const updatedOptions = specialOfferFilters.includes(value)
            ? specialOfferFilters.filter((item) => item !== value)
            : [...specialOfferFilters, value]

          dispatch(setSpecialOfferFilterOptions(updatedOptions))
          dispatch(fetchPractitionerListings())
        }

        return (
          <Chip
            label={
              <Box className={classes.filterChipLabelContainer}>
                <Typography className={classes.filterChipLabelLeft}>Stella Exclusive:</Typography>
                <Typography className={classes.filterChipLabelRight}>
                  {getDisplayValue(specialOffers, filter)}
                </Typography>
              </Box>
            }
            value={filter}
            onClick={clickHandler}
            onDelete={clickHandler}
            className={classes.filterChipContainer}
            color="primary"
            key={filter}
          />
        )
      })
    )
    renderedChips.push(
      areaFilters.map((filter) => {
        const clickHandler = (value) => {
          const updatedOptions = areaFilters.includes(value)
            ? areaFilters.filter((item) => item !== value)
            : [...areaFilters, value]

          dispatch(setAreaFilterOptions(updatedOptions))
          dispatch(fetchPractitionerListings())
        }

        return (
          <Chip
            label={
              <Box className={classes.filterChipLabelContainer}>
                <Typography className={classes.filterChipLabelLeft}>Area:</Typography>
                <Typography className={classes.filterChipLabelRight}>
                  {getDisplayValue(areas, filter)}
                </Typography>
              </Box>
            }
            value={filter}
            onClick={clickHandler}
            onDelete={clickHandler}
            className={classes.filterChipContainer}
            color="primary"
            key={filter}
          />
        )
      })
    )
    renderedChips.push(
      experienceFilters.map((filter) => {
        const clickHandler = (value) => {
          const updatedOptions = experienceFilters.includes(value)
            ? experienceFilters.filter((item) => item !== value)
            : [...experienceFilters, value]

          dispatch(setExperienceFilterOptions(updatedOptions))
          dispatch(fetchPractitionerListings())
        }

        return (
          <Chip
            label={
              <Box className={classes.filterChipLabelContainer}>
                <Typography className={classes.filterChipLabelLeft}>Experience:</Typography>
                <Typography className={classes.filterChipLabelRight}>
                  {getDisplayValue(experienceLevels, filter)} years
                </Typography>
              </Box>
            }
            value={filter}
            onClick={clickHandler}
            onDelete={clickHandler}
            className={classes.filterChipContainer}
            color="primary"
            key={filter}
          />
        )
      })
    )
    renderedChips.push(
      specialisationFilters.map((filter) => {
        const clickHandler = (value) => {
          const updatedOptions = specialisationFilters.includes(value)
            ? specialisationFilters.filter((item) => item !== value)
            : [...specialisationFilters, value]

          dispatch(setSpecialisationFilterOptions(updatedOptions))
          dispatch(fetchPractitionerListings())
        }

        return (
          <Chip
            label={
              <Box className={classes.filterChipLabelContainer}>
                <Typography className={classes.filterChipLabelLeft}>Specialisation:</Typography>
                <Typography className={classes.filterChipLabelRight}>
                  {getDisplayValue(specializations, filter)}
                </Typography>
              </Box>
            }
            value={filter}
            onClick={clickHandler}
            onDelete={clickHandler}
            className={classes.filterChipContainer}
            color="primary"
            key={filter}
          />
        )
      })
    )

    // if (consultationFilter.name.length > 0) {
    //   const clickHandler = (value) => {
    //     const updatedOptions =
    //       consultationFilter.name === value ? { name: '', category: '' } : value
    //     dispatch(setConsultationFilterOption(updatedOptions))
    //     dispatch(fetchPractitionerListings())
    //   }

    //   renderedChips.push(
    //     <Chip
    //       label={
    //         <Box className={classes.filterChipLabelContainer}>
    //           <Typography className={classes.filterChipLabelLeft}>Consultation:</Typography>
    //           <Typography className={classes.filterChipLabelRight}>
    //             {consultationFilter.name}
    //           </Typography>
    //         </Box>
    //       }
    //       value={consultationFilter.name}
    //       onClick={clickHandler}
    //       onDelete={clickHandler}
    //       className={classes.filterChipContainer}
    //       color="primary"
    //       key={consultationFilter.name}
    //     />
    //   )
    // }

    if (maxStartPriceFilter && maxStartPriceFilter > 0) {
      const clickHandler = (value) => {
        const updatedOptions = maxStartPriceFilter === value ? 0 : value
        dispatch(setMaxStartPriceFilterOption(updatedOptions))
        dispatch(fetchPractitionerListings())
      }

      renderedChips.push(
        <Chip
          label={
            <Box className={classes.filterChipLabelContainer}>
              <Typography className={classes.filterChipLabelLeft}>Max starting price:</Typography>
              <Typography className={classes.filterChipLabelRight}>
                ${maxStartPriceFilter}
              </Typography>
            </Box>
          }
          value={maxStartPriceFilter}
          onClick={clickHandler}
          onDelete={clickHandler}
          className={classes.filterChipContainer}
          color="primary"
          key="maxStartingPrice"
        />
      )
    }

    return renderedChips
  }

  if (shouldRenderFilterChips) {
    return (
      <Box className={classes.filterChipsContainer}>
        <Button uiType="tertiary" className={classes.clearAllButton} onClick={clearAllClickHandler}>
          Clear all
        </Button>
        {renderFilterChips()}
      </Box>
    )
  }

  return null
}
