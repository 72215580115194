import { Box, Typography } from '@material-ui/core'

import { useOnlineConsultationRequiredDialogStyles } from './hooks'

export const OnlineConsultationRequiredDialog = ({ consultation }) => {
  const { classes } = useOnlineConsultationRequiredDialogStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>
        {`As per Australian regulations, ${consultation} consultation pricing is available only through an
          online or in-person consultation.`}
      </Typography>
    </Box>
  )
}
